import React from 'react'

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#131017] text-gray-300'>
         <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
                <div className='sm:text-right pb-8 pl-4'>
                    <p className='text-4xl font-bold inline border-b-4 border-orange-600'>About</p>
                </div>
                <div>
                    
                </div>
            </div>
            <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
                <div className='sm:text-right text-4xl font-bold'>
                    <p className='selection:bg-orange-600'>Hi, I'm Shir Weinbrand, nice to meet you.</p>
                </div>
                <div>
                    <p className='text-xl selection:bg-orange-600'>
                    I am a PhD candidate at the ADM+S Centre at Queensland University of Technology (QUT).<br/><br/>Prior to joining QUT, I served as a data analyst and a scientific lab manager. Obtained a B.A. in Communication and The Multidisciplinary Program in Humanities and M.A. in Communication and Media Studies (both Cum Laude).<br/>As part of my M.A. thesis, I investigated exposure to news diversity on Facebook and in news websites using web tracking data and computational methods.<br/><br/>Previously, served as a Teaching Assistant for Python courses and a Research Assistant in Media User Interaction Lab.<br/><br/>My academic journey has been further enriched by courses in Big Data Analysis using R, Computational Analysis of Text in Python, Introduction to Computer Science, and Network Analysis.
                    <br/><br/>I am profoundly interested in computational social science, search engines, digital media, political communication, social media, AI, big data, and data visualization.
                    <br/><br/>Off-academia, I like to 🏋🏼‍♀️, 🚴🏼‍♀️ & ⚽️.<br/>Feel free to contact me.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About